<template>
  <b-modal
    :id="id"
    ref="customModalRef"
    v-model="model"
    centered
    class="custom-modal-container"
    :hide-footer="!hasFooterTemplate"
    :hide-header="!hasHeaderTemplate && !title"
    :no-close-on-backdrop="noCloseOnBackDrop"
    :hide-header-close="hideHeaderClose"
    :no-close-on-esc="noCloseOnEsc"
    :size="size"
    :title="!hasHeaderTemplate ? title : null"
    @change="$emit('input', false)"
  >
    <template v-if="hasHeaderTemplate" #modal-header>
      <slot name="header" />
    </template>
    <slot />
    <template v-if="hasFooterTemplate" #modal-footer>
      <slot name="footer" />
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'CustomModal',
  props: {
    id: {
      type: String,
      default: () => 'customModalRef',
    },
    title: {
      type: String,
      default: () => null,
    },
    size: {
      type: String,
      default: () => 'xl',
    },
    noCloseOnBackDrop: {
      type: Boolean,
      default: () => false,
    },
    hideHeaderClose: {
      type: Boolean,
      default: () => false,
    },
    noCloseOnEsc: {
      type: Boolean,
      default: () => false,
    },
    value: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      model: false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val != this.model) this.model = val;
      }
    }
  },
  computed: {
    hasHeaderTemplate() {
      return !!this.$slots.header;
    },
    hasFooterTemplate() {
      return !!this.$slots.footer;
    },
  },
  methods: {
    show() {
      this.$refs.customModalRef.show();
      this.$emit('input', true);
    },
    hide() {
      this.$refs.customModalRef.hide();
      this.$emit('input', false);
    },
    toggle() {
      this.$refs.customModalRef.toggle();
      this.$emit('input', !this.value);
    },
  },
};
</script>

<style>
  .modal-dialog.modal-xxl {
    max-width: 90%;
    width: 90%;
  }
</style>
